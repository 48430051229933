import {
    TechRadarApi,
    TechRadarLoaderResponse,
  } from '@backstage-community/plugin-tech-radar';
  
import { DiscoveryApi, FetchApi, IdentityApi } from "@backstage/core-plugin-api";


  export class TechRadarClient implements TechRadarApi {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
    identityApi: IdentityApi

    constructor({discoveryApi, fetchApi, identityApi }: {discoveryApi: DiscoveryApi, fetchApi: FetchApi, identityApi: IdentityApi}) {
      this.discoveryApi = discoveryApi;
      this.fetchApi = fetchApi;
      this.identityApi = identityApi;
  }
    
    async load(): Promise<TechRadarLoaderResponse> {
      // if needed id prop can be used to fetch the correct data

      const data = await fetch('TechRadarData.json').then(res => res.json());
      console.log(data)
      // For example, this converts the timeline dates into date objects
      return {
        ...data,
        entries: data.entries.map((entry:any) => ({
          ...entry,
          timeline: entry.timeline.map((timeline:any) => ({
            ...timeline,
            date: new Date(timeline.date),
          })),
        })),
      };
    }
  }
